import { graphql } from 'gatsby';
import React from 'react';
import PlaybookDetailsHero from 'components/Page/PlaybookDetails/PlaybookDetailsHero';
import TableOfContentsProgress from 'components/Page/PlaybookDetails/TableOfContentsProgress';
import PlaybookStickyFooter from 'components/Page/PlaybookDetails/PlaybookStickyFooter';
import PlaybookDetailsContent from 'components/Page/PlaybookDetails/PlaybookDetailsContent';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';

const PlaybookDetails = (props) => {
  const chapterData = props.data.contentfulContentBlock;
  const { playbookContentfulId } = props.pageContext;
  const { seoData, section } = props.data.contentfulPage;
  const playbookHeroBG = props.data.contentfulImage.image.file.url;
  const { section_info: sectionInfo } = props.pageContext;
  const allChapters = sectionInfo.playbook_data;

  const seo = createSEO(
    {
      ...seoData,
    },
    props,
  );

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Playbook',
        icon: null,
        url: sectionInfo.splashPageSlug,
      },
      {
        title: `Chapter ${sectionInfo.current_chapter + 1}`,
        icon: null,
        url: null,
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  return (
    <WebsiteLayout
      headerTheme="TRANS_BG_WHITE_COLOR"
      hero={{ backgroundImage: props.data.contentfulImage }}
      breadcrumbs={breadcrumbs}
      layout="industry"
    >
      <SEO {...seo} />
      <PlaybookDetailsHero
        title={chapterData.title}
        description={chapterData.description?.subhead}
        sections={chapterData.sections}
        sectionInfo={sectionInfo}
        playbookHeroBG={playbookHeroBG}
      />
      <PlaybookDetailsContent
        playbookContentfulId={playbookContentfulId}
        section={section[0]}
        sectionInfo={sectionInfo}
      />
      <TableOfContentsProgress
        playbookContentfulId={playbookContentfulId}
        sectionInfo={sectionInfo}
        chapters={allChapters}
      />
      <PlaybookStickyFooter sections={chapterData.sections} sectionInfo={sectionInfo} />
    </WebsiteLayout>
  );
};
export default PlaybookDetails;

export const PlaybookDetailsQuery = graphql`
  query PlaybookDetailsQuery($chapter_id: String!, $section_id: String!) {
    contentfulPage(contentful_id: { eq: $section_id }) {
      updatedAt
      seoData: seo {
        ...Seo
      }
      section: components {
        ... on ContentfulRichText {
          title: sectionTitle
          ...RichText
        }
      }
    }
    contentfulContentBlock(contentful_id: { eq: $chapter_id }) {
      title: heading
      description: subhead {
        subhead
      }
      sections: collection {
        ... on ContentfulPage {
          seo {
            ...Seo
          }
        }
      }
    }
    contentfulRichText(contentful_id: { eq: $section_id }) {
      slug: anchorId
      title: sectionTitle
      ...RichText
    }
    contentfulImage(internalName: { eq: "playbook-details-bg" }) {
      image {
        file {
          url
        }
      }
      alt
    }
  }
`;
